<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <!-- 3 Card of Total Product + Total Active Product + Add New -->
      <b-row v-if="masterSetup.title">
        <b-col
          md="6"
          lg="4"
        >
          <!-- Card Total Product -->
          <b-card>
            <b-card-text>
              Total {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(masterSetup.total) +' '+ masterSetup.cardTitle }}
            </h3>

            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >

          <!-- Card Active Product -->
          <b-card>
            <b-card-text>
              Active {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(masterSetup.totalActive) +' '+ masterSetup.cardTitle }}
            </h3>
            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- End of 3 Card of Total Product + Total Active Product + Add New -->

      <!-- Table Card -->
      <b-card>
        <b-row>
          <b-col cols="12">
            <table-list
              v-if="masterSetup.getDataEndpoint"
              :master-setup="masterSetup"
              :refresh-data="refreshData"
              :convert-number="convertNumber"
              @export-table="exportTable"
              @edit-modal="openEditModal"
              @detail-modal="openDetailModal"
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- Modal for New Product -->
      <b-modal
        ref="masterModal"
        scrollable
        :title="modalInfo.title"
        cancel-title="Close"
        ok-title="Verified"
        size="lg"
        cancel-variant="outline-secondary"
        @ok="submitModal"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div>
            <b-row class="mb-1">
              <b-col cols="6">
                Status
              </b-col>
              <b-col cols="6">
                : {{ productModel.status }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Payment Type
              </b-col>
              <b-col cols="6">
                : {{ productModel.payment_type }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Bukti Pembayaran
              </b-col>
              <b-col
                v-if="productModel.image_url"
                cols="6"
              >
                : <b-link
                  :href="productModel.image_url"
                  target="_blank"
                  class="alert-link text-info"
                >
                  <span>Image</span>
                </b-link>
              </b-col>
              <b-col
                v-else
                cols="6"
              >
                : N/A
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Total Module
              </b-col>
              <b-col cols="6">
                : {{ productModel.total_qty }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Sub Total
              </b-col>
              <b-col cols="6">
                : {{ convertNumber(Math.round(productModel.amount)) }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Tax Rate
              </b-col>
              <b-col cols="6">
                : {{ productModel.tax_rate }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Tax Amount
              </b-col>
              <b-col cols="6">
                : {{ convertNumber(Math.round(productModel.amount * (productModel.tax_rate/100))) }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Total
              </b-col>
              <b-col cols="6">
                : {{ convertNumber(Math.round(productModel.amount + (productModel.amount * (productModel.tax_rate/100)))) }}
              </b-col>
            </b-row>
            <h5>Detail Module</h5>
            <b-table
              :items="productModel.detail_order"
              :fields="detailFields"
            >
              <template #cell(qty)="data">
                {{ data.item.module_name === 'Periode' ? `${convertNumber(data.item.qty)} Months` : convertNumber(data.item.qty) }}
              </template>
              <template #cell(amount)="data">
                {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.amount) }}
              </template>
              <template #cell(total)="data">
                {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.qty * data.item.amount) }}
              </template>
            </b-table>
          </div>
        </b-overlay>
      </b-modal>

      <!-- Modal for Detail Product -->
      <b-modal
        ref="detailModal"
        scrollable
        title="Detail Invoice"
        ok-only
        size="lg"
        ok-title="Close"
        cancel-variant="outline-secondary"
      >
        <div>
          <b-row class="mb-1">
            <b-col cols="6">
              Status
            </b-col>
            <b-col cols="6">
              : {{ productModel.status }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Payment Type
            </b-col>
            <b-col cols="6">
              : {{ productModel.payment_type }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Bukti Pembayaran
            </b-col>
            <b-col
              v-if="productModel.image_url"
              cols="6"
            >
              : <b-link
                :href="productModel.image_url"
                target="_blank"
                class="alert-link text-info"
              >
                <span>Image</span>
              </b-link>
            </b-col>
            <b-col
              v-else
              cols="6"
            >
              : N/A
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Total Module
            </b-col>
            <b-col cols="6">
              : {{ productModel.total_qty }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Sub Total
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount)) }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Tax Rate
            </b-col>
            <b-col cols="6">
              : {{ productModel.tax_rate }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Tax Amount
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount * (productModel.tax_rate/100))) }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Total
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount + (productModel.amount * (productModel.tax_rate/100)))) }}
            </b-col>
          </b-row>
          <h5>Detail Module</h5>
          <b-table
            :items="productModel.detail_order"
            :fields="detailFields"
          >
            <template #cell(qty)="data">
              {{ data.item.module_name === 'Periode' ? `${convertNumber(data.item.qty)} Months` : convertNumber(data.item.qty) }}
            </template>
            <template #cell(amount)="data">
              {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.amount) }}
            </template>
            <template #cell(total)="data">
              {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.qty * data.item.amount) }}
            </template>
          </b-table>
        </div>
      </b-modal>

      <!-- Modal for Import Product -->
      <b-modal
        id="modal-center"
        ref="importModal"
        centered
        :title="'Import '+masterSetup.title "
        cancel-title="Close"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @ok="submitImport"
      >
        <b-card-text>
          Choose or Drag and Drop your file here. <br>
        </b-card-text>
        <validation-observer
          ref="importRules"
          tag="form"
        >
          <validation-provider
            #default="{ errors }"
            name="Import File"
            :rules="{
              required: true,
              ext: ['xls', 'xlsx'],
              size: 10240,
            }"
          >
            <b-form-file
              ref="importFile"
              v-model="importFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".xls, .xlsx"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <b-card-text>
              <br><i>Imported file should be <strong>(xls or xlsx)</strong> and the size <strong>aren't more than 10 Mb</strong>.</i>
            </b-card-text>
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(40, 199, 91, 0.15)'"
                v-b-modal.modal-center
                variant="flat-success"
                class="btn-icon mt-0"
                @click="downloadTemplate"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/ms-excel.png"
                  width="25px"
                  height="auto"
                > Click here to download template file!
              </b-button>
            </div>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
  BModal,
  BTable,
  BForm,
  VBModal,
  BFormCheckbox,
  BFormFile,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormDatepicker,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  between, integer, required, min, max, ext, size,
} from '@core/utils/validations/validations'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle,import/extensions
import TableList from './table/Table'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BLink,
    BCardText,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BModal,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormFile,
    BFormDatepicker,
    BOverlay,
    TableList,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      selectedStore: '',
      showLoading: false,
      // Product Data
      products: [],
      optionField: [],
      productModel: {},
      refreshData: 0,
      importFile: [],
      modalInfo: {
        title: '',
        type: '',
      },
      detailFields: [{ key: 'module_name', label: 'Module Name' },
        { key: 'qty', label: 'QTY' },
        // { key: 'expired', label: 'expired', sortable: true },
        { key: 'amount', label: 'Amount' },
        { key: 'total', label: 'Sub Total Amount' }],
      required,
      between,
      min,
      max,
      integer,
      ext,
      size,
    }
  },

  setup() {
    const { router } = useRouter()
    const masterSetup = ref({})
    const fieldOptions = ref({})
    const table = 'finance'
    useJwt.jwtGet(`/master/finance/setup-list/${table}`)
      .then(res => {
        masterSetup.value = res.data
        const selectField = res.data.fields.filter(f => f.type === 'select')
        // eslint-disable-next-line no-restricted-syntax
        for (const field of selectField) {
          useJwt.jwtGet(field.source)
            .then(resp => {
              fieldOptions.value[field.id] = resp.data
            })
        }
      }).catch(err => {
        if (err.response.status === 403) {
          useJwt.logout()
          router.replace({ name: 'auth-login' })
        } else {
          router.replace({ name: 'error-404' })
        }
      })
    return {
      masterSetup,
      fieldOptions,
    }
  },
  computed: {},
  methods: {
    exportTable() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.exportEndpoint).then(() => {
        makeToast(this, 'Export Success', 'EditIcon', 'success')
        this.showLoading = false
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    downloadTemplate() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.templateEndpoint).then(() => {
        this.showLoading = false
        makeToast(this, 'Download Template Success', 'EditIcon', 'success')
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    submitImport(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.validationImportForm().then(() => {
        this.showLoading = true
        const data = new FormData()
        data.append('file', this.$refs.importFile.files)
        useJwt.jwtPostFile(this.masterSetup.importEndpoint, data).then(() => {
          this.showLoading = false
          makeToast(this, 'Form Submitted', 'EditIcon', 'success')
          this.$refs.importModal.hide()
          this.importFile = []
          // eslint-disable-next-line no-plusplus
          this.refreshData++
        }).catch(e => {
          this.showLoading = false
          if (e.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
          makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
        })
      })
    },
    submitModal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.$swal({
        title: 'Are you sure to verified?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.showLoading = true
            const data = {
              id: this.productModel.id,
              statusPayment: this.modalInfo.statusPayment,
              statusOrder: this.modalInfo.statusOrder,
            }
            useJwt.jwtPost(this.modalInfo.submitEndpoint, data).then(() => {
              this.showLoading = false
              makeToast(this, 'Verified Success', 'EditIcon', 'success')
              this.$refs.masterModal.hide()
              this.productModel = {}
              // eslint-disable-next-line no-plusplus
              this.refreshData++
              useJwt.jwtPost(this.masterSetup.nextEditEndpoint, data).then(() => {
              }).catch(e => {
                console.log(e)
                this.showLoading = false
                if (e.response.status === 403) {
                  useJwt.logout()
                  this.$router.replace({ name: 'auth-login' })
                }
                makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
              })
            }).catch(e => {
              this.showLoading = false
              if (e.response.status === 403) {
                useJwt.logout()
                this.$router.replace({ name: 'auth-login' })
              }
              makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
            })
          }
        })
    },
    openEditModal(data) {
      this.showLoading = true
      store.dispatch('app-master/fetchMasterTable', { id: data.id, masterType: this.masterSetup.name, endpoint: this.masterSetup.getDetailEndpoint })
        .then(response => {
          this.showLoading = false
          this.modalInfo.title = 'Verified Payment'
          this.modalInfo.type = 'edit'
          this.modalInfo.statusPayment = 'VERIFIED'
          this.modalInfo.statusOrder = 'PAID'
          this.productModel = response.data
          this.productModel.id = data.id
          this.modalInfo.submitEndpoint = this.masterSetup.editEndpoint
          this.$refs.masterModal.show()
        }).catch(err => {
          this.showLoading = false
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
        })
    },
    openDetailModal(data) {
      store.dispatch('app-billing/fetchMasterTable', { id: data.id, masterType: this.masterSetup.name, endpoint: this.masterSetup.getDetailEndpoint })
        .then(response => {
          this.productModel = response.data
          this.$refs.detailModal.show()
        }).catch(err => {
          this.showLoading = false
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
        })
    },
    filterOption(data, field) {
      if (field.parent_id > 0) {
        const parent = this.masterSetup.fields.find(e => e.id === field.parent_id)
        const filtered = data.filter(e => this.productModel[parent.name] && e.parent_id === this.productModel[parent.name])
        if (filtered.length === 0) this.productModel[field.name] = ''
        return filtered
      }
      return data
    },
    resetChild(data, field) {
      if (field.parent_id > 0) return this.filterOption(data, field)
      const child = this.masterSetup.fields.find(e => e.parent_id === field.id).name
      if (this.productModel[field.name]) this.productModel[child] = ''
      return true
    },
    // confirm texrt
    deleteConfirm(data) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.showLoading = true
            useJwt.jwtGet(this.masterSetup.deleteEndpoint, { id: data.id }).then(() => {
              this.showLoading = false
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // eslint-disable-next-line no-plusplus
              this.refreshData++
            })
              .catch(e => {
                this.showLoading = false
                if (e.response.status === 403) {
                  useJwt.logout()
                  this.$router.replace({ name: 'auth-login' })
                }
                makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
              })
          }
        })
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.masterRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    },
    validationImportForm() {
      return new Promise((resolve, reject) => {
        this.$refs.importRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
